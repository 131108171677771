import i18n from '@/libs/i18n';
import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const investmentRequestColumns = [
  {
    label: i18n.t('investments.global.field.sort'),
    field: 'sort',
  },
  {
    label: i18n.t('investments.global.field.type'),
    field: 'type',
  },
  {
    label: i18n.t('investments.global.field.count'),
    field: 'count',
  },
  {
    label: i18n.t('investments.global.field.price'),
    field: 'price',
  },
  {
    label: i18n.t('investments.global.field.provider'),
    field: 'provider',
  },
  {
    label: i18n.t('investments.global.field.attachment'),
    field: 'attachment',
  },
  {
    label: i18n.t('investments.global.field.accepted'),
    field: 'accepted',
  },
];
// TODO store?
export default ref(createTable('investments', investmentRequestColumns));
