import i18n from '@/libs/i18n';
import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const tableI18n = 'investments.form.investment.tab.subject.table';
const investmentObjectsColumns = [
  {
    label: i18n.t(`${tableI18n}.technology`),
    field: 'technology',
  },
  {
    label: i18n.t(`${tableI18n}.diameter`),
    field: 'diameter',
  },
  {
    label: i18n.t(`${tableI18n}.quantity`),
    field: 'quantity',
  },
  {
    label: i18n.t(`${tableI18n}.length`),
    field: 'length',
  },
  {
    label: i18n.t(`${tableI18n}.mb`),
    field: 'mb',
  },
  {
    label: i18n.t(`${tableI18n}.reinforcement`),
    field: 'reinforcement',
  },
];

export default ref(createTable(null, investmentObjectsColumns));
