var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('div', {
          staticClass: "widget-header"
        }, [_c('h2', {
          staticClass: "widget-header-name"
        }, [_vm._v(" " + _vm._s(_vm.investment.name) + " ")]), _c('div', {
          staticClass: "widget-header-serial"
        }, [_vm._v(" " + _vm._s(_vm.investment.serial) + " ")])]), _c('widget-card-controls', {
          attrs: {
            "editPermission": "investment.investment.update",
            "deletePermission": "investment.investment.destroy"
          },
          on: {
            "edit": function ($event) {
              return _vm.$router.push({
                name: 'investments.details.settings'
              });
            },
            "delete": _vm.deleteInvestment
          }
        })];
      },
      proxy: true
    }])
  }, [_c('span', {
    staticClass: "additional-buttons"
  }), _c('investments-details-card-table', {
    attrs: {
      "investment": _vm.investment
    }
  }), _vm.investmentCurrentOffer ? _c('div', {
    staticClass: "current-offer"
  }, [_c('a', {
    attrs: {
      "href": _vm.investmentCurrentOffer.file_link
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "LinkIcon",
      "size": "16"
    }
  }), _vm._v(" Pobierz aktualną ofertę inwestycji ")], 1)]) : _c('div', {
    staticClass: "current-offer"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "InfoIcon",
      "size": "16"
    }
  }), _vm._v(" Inwestycja nie ma przypisanej oferty. ")], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }