var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    attrs: {
      "title": "Przeroby"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "processing-buttons"
        }, [_c('authorization-guard', {
          attrs: {
            "permission": "investment.subject_balance.store",
            "silent": ""
          }
        }, [_c('vs-button', {
          attrs: {
            "vs-variant": "primary"
          },
          on: {
            "click": _vm.openReportModal
          }
        }, [_vm._v(" + Raport dzienny ")])], 1), _c('authorization-guard', {
          attrs: {
            "permission": "investment.subject_balance.index",
            "silent": ""
          }
        }, [_c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": _vm.openInvestmentReports
          }
        }, [_vm._v(" Historia ")])], 1), _c('authorization-guard', {
          attrs: {
            "permission": "investment.subject_balance.index",
            "silent": ""
          }
        }, [_c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": _vm.openInvestmentWorkReport
          }
        }, [_vm._v(" Raport przerobów ")])], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "report-date"
  }, [_vm._v(" z dnia " + _vm._s(_vm.formatDate.training(_vm.reportDate)) + " ")]), _c('authorization-guard', {
    attrs: {
      "permission": "investment.subject_balance.index"
    }
  }, [_c('div', {
    staticClass: "processing-list"
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": ""
    }
  }) : _vm._e(), _vm._l(_vm.processing, function (item) {
    return _c('div', {
      key: item.name,
      staticClass: "processing-list-item"
    }, [_c('div', {
      staticClass: "processing-list-item-icon"
    }, [_c('feather-icon', {
      attrs: {
        "icon": item.icon,
        "size": "24"
      }
    })], 1), _c('div', {
      staticClass: "processing-list-item-text"
    }, [_c('div', {
      staticClass: "value"
    }, [_vm._v(" " + _vm._s(item.value) + " ")]), _c('div', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(item.name) + " ")])])]);
  })], 2)]), _c('investments-report-modal', {
    on: {
      "success": _vm.updateSummary
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }