var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    staticClass: "ordered-object-card",
    attrs: {
      "title": "Przedmiot zamówienia"
    }
  }, [_c('table', [_c('tbody', [_c('tr', [_c('td', [_vm._v("grupa:")]), _c('td', {
    staticClass: "list"
  }, _vm._l(_vm.investment.work_groups, function (group) {
    return _c('span', {
      key: group.id
    }, [_vm._v(" " + _vm._s(group.name) + " ")]);
  }), 0)]), _c('tr', [_c('td', [_vm._v("typ robót:")]), _c('td', {
    staticClass: "list"
  }, _vm._l(_vm.investment.work_types, function (type) {
    return _c('span', {
      key: type.id
    }, [_vm._v(" " + _vm._s(type.name) + " ")]);
  }), 0)])])]), _c('authorization-guard', {
    attrs: {
      "permission": "investment.subject.index"
    }
  }, [_c('investments-ordered-objects-card-table', {
    staticClass: "object-table",
    attrs: {
      "subjects": _vm.subjects
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }