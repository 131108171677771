var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    attrs: {
      "title": "Do końca inwestycji"
    }
  }, [_c('h2', [_vm._v(_vm._s(_vm.daysTillEnd))]), _c('investment-progress-card-table', {
    attrs: {
      "investment": _vm.investment
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }