<template>
  <widget-card>
    <attachments useProps useTabs
      :items="parsedAttachments"
      :uploading="uploading"

      @add="uploadAttachment"
      @remove="removeAttachment"
    />
  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import Attachments from '@/components/ui/attachments/Attachments.vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { computed, ref } from 'vue';
import showToast from '@/libs/toasts';
import {
  AttachmentCreateError,
  AttachmentCreateSuccess,
  AttachmentDeleteError,
  AttachmentDeleteSuccess,
  AttachmentSizeError,
} from '@/libs/toasts/feedback/module/tasks';

export default {
  name: 'InvestmentsAttachmentsCard',
  props: {
    investmentId: {
      type: Number,
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const uploading = ref(false);
    const attachments = ref(props.files ?? []);

    const parsedAttachments = computed(() => attachments.value.map((attachment) => ({
      ...attachment.media,
      id: attachment.id,
      type: attachment.type,
      file_link: attachment.file_link,
    })));

    const uploadAttachment = (file) => {
      uploading.value = true;

      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('mediable_id', props.investmentId);
      formData.append('type', 'other');

      useInvestmentsApi()
        .uploadAttachment(props.investmentId, formData)
        .then(({ data }) => {
          uploading.value = false;
          showToast(AttachmentCreateSuccess);
          attachments.value.push(data.data);
        })
        .catch(({ response }) => {
          if (response.status === 413 || response.status === 422) {
            showToast(AttachmentSizeError);
          } else {
            showToast(AttachmentCreateError);
          }
          uploading.value = false;
        });
    };

    const removeAttachment = async (id) => {
      useInvestmentsApi()
        .deleteAttachment(id)
        .then(() => {
          showToast(AttachmentDeleteSuccess);

          attachments.value = attachments.value.filter((a) => a.id !== id);
        })
        .catch(() => {
          showToast(AttachmentDeleteError);
        });
    };

    return {
      uploadAttachment,
      removeAttachment,

      uploading,
      parsedAttachments,
    };
  },
  components: { WidgetCard, Attachments },
};
</script>
