var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investment-order-objects-table"
  }, [_c('vs-table', {
    attrs: {
      "columns": _vm.extendedColumns,
      "rows": _vm.subjects,
      "showPagination": false,
      "showSearch": false
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'technology' && row.technology ? _c('span', [_vm._v(" " + _vm._s(row.technology.name) + " ")]) : _vm._e(), column.field === 'diameter' ? _c('span', [_vm._v(" " + _vm._s(Number(row.diameter).toLocaleString()) + " mm ")]) : _vm._e(), column.field === 'quantity' ? _c('span', [_vm._v(" " + _vm._s(Number(row.quantity).toLocaleString()) + " ")]) : _vm._e(), column.field === 'length' ? _c('span', [_vm._v(" " + _vm._s(Number(row.length).toLocaleString()) + " m ")]) : _vm._e(), column.field === 'mb' ? _c('span', [_vm._v(" " + _vm._s((row.length * row.quantity).toLocaleString()) + " mb ")]) : _vm._e(), column.field === 'progress' ? _c('span', {
          staticClass: "subject-progress"
        }, [_c('div', {
          staticClass: "progress-percentage"
        }, [_vm._v(" " + _vm._s(row.percentage || 0) + "% ")]), _c('div', {
          staticClass: "progress-wrapper"
        }, [_c('b-progress', {
          attrs: {
            "value": row.percentage || 0,
            "max": "100"
          }
        })], 1)]) : column.field === 'reinforcement' && row.reinforcement ? _c('span', [_vm._v(" " + _vm._s(row.reinforcement.name) + " ")]) : _vm._e()];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }