<template>
  <table>
    <tbody>
      <tr>
        <td>status</td>
        <td>
          <vs-status-badge-investment :id="investment.investment_status_id" />
        </td>
      </tr>
      <tr>
        <td>region</td>
        <td>
          <branch-label :id="investment.core_branch_id" />
        </td>
      </tr>
      <tr>
        <td>nr. umowy zewnętrznej</td>
        <td>
          {{ investment.external_contract }}
        </td>
      </tr>
      <tr>
        <td>inwestor</td>
        <td>
          <span v-if="investment.investor">
            <crm-company-label :integrated="investment.investor" />
          </span>
        </td>
      </tr>
      <tr>
        <td>zleceniodawca</td>
        <td>
          <span v-if="investment.principal">
            <crm-company-label :integrated="investment.principal" />
          </span>
        </td>
      </tr>
      <tr>
        <td>adres</td>
        <td>
          {{ investment.street }}
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <span class="separate-element">
            {{ investment.post_code }} {{ investment.city }}
          </span>
          <span class="separate-element" v-if="investment.plot_number">
            dz. nr
            {{ investment.plot_number }}
          </span>
        </td>
      </tr>
      <tr>
        <td>współrzędne</td>
        <td>
          {{ investment.latitude || '-' }}, {{ investment.longitude || '-' }}
        </td>
      </tr>
      <tr>
        <td>opis</td>
        <td>
          {{ investment.address_description }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import BranchLabel from '@/components/ui/BranchLabel.vue';
import VsStatusBadgeInvestment from '@/components/ui/vs-status-badge/investment/VsStatusBadgeInvestment.vue';
import CrmCompanyLabel from '@/components/module/crm/CrmCompanyLabel.vue';

export default {
  components: {
    BranchLabel,
    VsStatusBadgeInvestment,
    CrmCompanyLabel,
  },
  name: 'InvestmentsDetailsCardTable',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="sass" scoped>
.separate-element
  &:not(:last-child)
    &:after
      content: ', '
table
  line-height: 1.7

  td:nth-child(1)
    font-weight: 300

  td:nth-child(2)
    padding-left: 20px

  td
    vertical-align: top

</style>
