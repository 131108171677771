<template>
  <widget-card>

    <authorization-guard
      permission="investment.requirement.index"
    >
      <div
        class="demands"
        v-if="materials.length > 0"
        >

        <div class="demand-summary">
          Łączna wartość zapotrzebowania inwestycji:

          <div class="demand-summary-value">
            {{ investmentTotalValue }} PLN
          </div>
        </div>

        <b-tabs
          class="tabs"
          vertical
          pills
        >
          <b-tab
            v-for="material in materials"
            :key="material.id"
            :title="material.name"
          >
            <investments-demand-details
              :editable="false"
              :key="material.id"

              :materialId="material.id"
              :unitId="material.core_unit_id"

              :demands="material.demands"
              :offers="material.offers"
            />
          </b-tab>
        </b-tabs>

      </div>

      <div class="no-address-info" v-else>
        <div class="wrapper">
          <feather-icon class="icon" icon="SettingsIcon" size="36" />
          <div class="text">
            Inwestycja nie ma jeszcze zgłoszonego zapotrzebowania.
          </div>
          <router-link :to="{ name: 'investments.details.settings' }">
            Przejdź do ustawień, aby je dodać
          </router-link>
        </div>
      </div>

    </authorization-guard>

  </widget-card>
</template>

<script>
import {
  BTab,
  BTabs,
} from 'bootstrap-vue';
import WidgetCard from '@/components/ui/WidgetCard.vue';
import investmentsRequestTable from '@/hooks/tables/investments/investmentsRequest';
import router from '@/router';
import { computed, ref } from 'vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import InvestmentsDemandDetails from '../ui/InvestmentsDemandDetails.vue';

export default {
  name: 'InvestmentsRequestCard',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const {
      table, updateSearchTerm, searchTerm, columns,
      onPageChange, rows,
    } = investmentsRequestTable.value;

    const sampleDemands = ref(props.investment?.requirements);
    const sampleOffers = ref(props.investment?.requirement_offers);

    // FIXME: compute with real data
    const materials = computed(() => {
      const demands = {};

      const groupByMaterial = (array, name) => array.forEach((element) => {
        if (!demands[element.investment_requirement_group_id]) {
          demands[element.investment_requirement_group_id] = {
            ...element.requirement_group,
          };
        }

        if (!demands[element.investment_requirement_group_id][name]) {
          demands[element.investment_requirement_group_id][name] = [];
        }

        demands[element.investment_requirement_group_id][name].push(element);

        return demands;
      });

      groupByMaterial(sampleDemands.value, 'demands');
      groupByMaterial(sampleOffers.value, 'offers');

      return Object.keys(demands).map((demand) => ({
        demands: [],
        offers: [],
        ...demands[demand],
      }));
    });

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Pen',
        callback: (obj) => {
          router.push({ name: 'crm', params: { id: obj.id } });
        },
      },
    ];

    const investmentTotalValue = computed(() => {
      const sum = sampleDemands.value.reduce((prev, curr) => prev + (curr.price * curr.value), 0);
      return sum.toLocaleString();
    });

    return {
      table,
      rows,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
      materials,
      investmentTotalValue,
    };
  },
  components: {
    BTab,
    BTabs,
    WidgetCard,
    InvestmentsDemandDetails,
    AuthorizationGuard,
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/scss/variables/_variables.scss'

.demand-summary
  padding: 5px 10px 20px 10px

  &-value
    display: inline
    font-weight: 700

.no-address-info
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  height: 100%

  .wrapper
    .icon
      opacity: 0.3
    .text
      font-size: 12pt
      font-weight: 500
      opacity: 0.8
      margin: 20px 0

.tabs::v-deep
  display: flex !important
  align-items: flex-start !important
  text-align: left

  .nav
    width: 170px

    .nav-item
      display: flex
      align-items: center
      padding-left: 0px

      a.nav-link
        padding: 15px 10px
        font-weight: 500
        opacity: 0.7

        &.active
          background: none
          color: $primary
          border: none
          box-shadow: none
          font-weight: 600
          opacity: 1

.additional-buttons
  margin-left: 20px
</style>
