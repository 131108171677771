<template>
  <investments-resource-details-list
    :editable="false"
    :investment="investment"
    :resources="resources"
  />
</template>

<script>
import router from '@/router';
import { computed } from 'vue';
import InvestmentsResourceDetailsList from './InvestmentsResourceDetailsList.vue';

export default {
  name: 'InvestmentsResourcesCardVehiclesTable',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const resources = computed(() => [
      ...props.investment?.base_resource,
    ]);

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Pen',
        callback: (obj) => {
          router.push({ name: 'crm', params: { id: obj.id } });
        },
      },
    ];

    return {
      actionItems,
      resources,
    };
  },
  components: { InvestmentsResourceDetailsList },
};
</script>
