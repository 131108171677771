<template>
  <widget-card title="Do końca inwestycji">

    <!-- FIXME: taken from scheduler_work_end -->
    <h2>{{ daysTillEnd }}</h2>

    <investment-progress-card-table
      :investment="investment"
    />

  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import InvestmentProgressCardTable from '@/components/views/investments/ui/InvestmentsProgressCardTable.vue';
import { computed } from 'vue';

export default {
  name: 'InvestmentsFinishCard',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const daysTillEnd = computed(() => {
      if (!props.investment?.contract_scheduler?.end_date) return '-';
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = new Date();
      const secondDate = new Date(props.investment.contract_scheduler.end_date);

      const diff = (firstDate - secondDate) / oneDay;

      if (diff >= 0) return 'Inwestycja ukończona';

      return `${Math.round(Math.abs(diff) + 1)} dni`;
    });

    return { daysTillEnd };
  },
  components: {
    InvestmentProgressCardTable,
    WidgetCard,
  },
};
</script>

<style lang="sass" scoped>
h2
  position: relative
  top: -10px
</style>
