var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('investments-resource-details-list', {
    attrs: {
      "editable": false,
      "investment": _vm.investment,
      "resources": _vm.resources
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }