<template>
  <widget-card>
    <template #header>
      <div class="widget-header">
        <h2 class="widget-header-name">
          {{ investment.name }}
        </h2>
        <div class="widget-header-serial">
          {{ investment.serial }}
        </div>
      </div>

      <widget-card-controls
        editPermission="investment.investment.update"
        deletePermission="investment.investment.destroy"
        @edit="$router.push({ name: 'investments.details.settings' })"
        @delete="deleteInvestment"
      />
    </template>

    <span class="additional-buttons">
      <!-- TODO: + Task + Raport -->
    </span>

    <investments-details-card-table
      :investment="investment"
    />

    <div class="current-offer" v-if="investmentCurrentOffer">
      <a :href="investmentCurrentOffer.file_link">
        <feather-icon icon="LinkIcon" size="16" />
        Pobierz aktualną ofertę inwestycji
      </a>
    </div>

    <div class="current-offer" v-else>
      <feather-icon icon="InfoIcon" size="16" />
      Inwestycja nie ma przypisanej oferty.
    </div>

  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import InvestmentsDetailsCardTable from '@/components/views/investments/ui/InvestmentsDetailsCardTable.vue';
import i18n from '@/libs/i18n';
import { deleteModal } from '@/libs/modals';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { useRouter } from '@/@core/utils/utils';
import showToast from '@/libs/toasts';
import { DeleteInvestmentError, DeleteInvestmentSuccess } from '@/libs/toasts/feedback/module/investments';
import { computed } from 'vue';
import WidgetCardControls from '@/components/ui/WidgetCardControls.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  name: 'InvestmentsDetailsCard',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { router } = useRouter();

    const deleteInvestment = async () => {
      const confirmation = await deleteModal({
        title: i18n.t('investments.modal.investment.delete.title'),
        text: i18n.t('investments.modal.investment.delete.text', {
          name: props.investment.name,
        }),
      });

      if (!confirmation) return false;

      const request = useInvestmentsApi()
        .deleteInvestment(props.investment.id)
        .then(() => {
          showToast(DeleteInvestmentSuccess, { name: props.investment.name });
          RemoteResourceManager.getInstance().notify('investments.investment');
          router.push({ name: 'investments' });
        })
        .catch(() => {
          showToast(DeleteInvestmentError);
        });

      return request;
    };

    const investmentCurrentOffer = computed(() => [...props.investment?.attachments ?? []]
      .reverse()
      .find((attachment) => attachment.type === 'offer'));

    return { deleteInvestment, investmentCurrentOffer };
  },
  components: {
    InvestmentsDetailsCardTable,
    WidgetCard,
    WidgetCardControls,
  },
};
</script>

<style lang="sass" scoped>
.current-offer
  margin: 20px 0 0 0
  display: flex
  align-items: center
  gap: 10px

  a
    display: flex
    align-items: center
    gap: 10px

.widget-header
  &-name
    margin-bottom: 7px

.additional-buttons
  margin-left: 20px
</style>
