<template>
  <widget-card>
    <notes
      v-if="notes"
      :items="notes"
      create-url="api/investment/notes"
      edit-url="api/investment/notes/:id"
      target-key="investment_id"
    />
  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import Notes from '@/components/ui/notes/Notes.vue';
import { ref } from 'vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';

export default {
  name: 'InvestmentsNotesCard',
  props: {
    investmentId: {
      type: Number,
    },
  },
  setup(props) {
    const notes = ref(null);

    const fetchInvestmentNotes = () => {
      useInvestmentsApi()
        .fetchInvestmentNotes(props.investmentId)
        .then(({ data }) => {
          notes.value = data.data;
        });
    };

    fetchInvestmentNotes();

    return { notes };
  },
  components: { WidgetCard, Notes },
};
</script>
