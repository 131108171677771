<template>
  <widget-card
    title="Przeroby"
    >

    <div class="report-date">
      z dnia {{ formatDate.training(reportDate) }}
    </div>

    <authorization-guard
      permission="investment.subject_balance.index"
    >
      <div class="processing-list">
        <vs-loader
          full
          v-if="isLoading"
        />

        <div
          class="processing-list-item"
          v-for="item in processing"
          :key="item.name"
          >

          <div class="processing-list-item-icon">
            <feather-icon
              :icon="item.icon"
              size="24"
            />
          </div>

          <div
            class="processing-list-item-text"
          >
            <div class="value">
              {{ item.value }}
            </div>

            <div class="name">
              {{ item.name }}
            </div>
          </div>

        </div>
      </div>
    </authorization-guard>

    <template #footer>
      <div class="processing-buttons">
        <authorization-guard
          permission="investment.subject_balance.store"
          silent
        >
          <vs-button
            vs-variant="primary"
            @click="openReportModal"
            >
            + Raport dzienny
          </vs-button>
        </authorization-guard>

        <authorization-guard
          permission="investment.subject_balance.index"
          silent
        >
          <vs-button
            vs-variant="light"
            @click="openInvestmentReports"
            >
            Historia
          </vs-button>
        </authorization-guard>

        <authorization-guard
          permission="investment.subject_balance.index"
          silent
        >
          <vs-button
            vs-variant="light"
            @click="openInvestmentWorkReport"
          >
            Raport przerobów
          </vs-button>
        </authorization-guard>
      </div>
    </template>

    <investments-report-modal
      @success="updateSummary"
    />
  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { computed, ref } from 'vue';
import showToast from '@/libs/toasts';
import { FetchWorkSummaryError } from '@/libs/toasts/feedback/module/investments';
import formatDate from '@/libs/date-formatter';
import store from '@/store';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import router from '@/router';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import InvestmentsReportModal from '../modals/InvestmentsReportModal.vue';

export default {
  name: 'InvestmentsProcessingCard',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    // get yesterday's date
    const date = new Date();
    date.setDate(date.getDate() - 1);

    const reportDate = formatDate.standard(date);
    const isLoading = ref(true);

    const { investmentAddReportModal } = useInvestmentsStore();
    const summary = ref({});

    const getUnit = store.getters['units/byId'];

    const totalPile = computed(() => summary.value?.subjects
      ?.reduce((prev, curr) => prev + curr.value, 0) ?? 0);

    const materials = computed(() => summary.value?.requirement_groups?.map((material) => ({
      ...material,
      value: `${material.value.toLocaleString()} ${getUnit(material.core_unit_id)?.code ?? 'n/a'}`,
      icon: 'DropletIcon',
    })) ?? []);

    const processing = computed(() => [
      {
        name: 'Praca palownic',
        value: `${(summary.value.mth ?? 0).toLocaleString()} mth`,
        icon: 'BatteryChargingIcon',
      },
      {
        name: 'Paliwo',
        value: `${(summary.value.fuel ?? 0).toLocaleString()} l`,
        icon: 'FilterIcon',
      },
      {
        name: 'Pale',
        value: `${(totalPile.value ?? 0).toLocaleString()} mb`,
        icon: 'BarChartIcon',
      },
      ...materials.value,
    ]);

    const updateSummary = () => {
      if (!props.investment.id) return;

      isLoading.value = true;
      useInvestmentsApi()
        .fetchWorkSummary(props.investment.id, { date_from: reportDate, date_to: reportDate })
        .then(({ data }) => {
          summary.value = data.data;
          isLoading.value = false;
        })
        .catch(() => {
          showToast(FetchWorkSummaryError);
          isLoading.value = false;
        });
    };

    updateSummary();

    const openReportModal = () => {
      investmentAddReportModal.value.visible = true;
      investmentAddReportModal.value.data = { investment_id: props.investment?.id ?? null };
    };

    const openInvestmentReports = () => {
      router.push({
        name: 'investments.reports',
        params: {
          investmentId: props.investment?.id ?? null,
        },
      });
    };

    const openInvestmentWorkReport = () => {
      router.push({
        name: 'investments.processing',
        params: {
          investmentId: props.investment?.id ?? null,
        },
      });
    };

    return {
      processing,
      reportDate,
      openReportModal,
      updateSummary,
      formatDate,
      isLoading,
      openInvestmentReports,
      openInvestmentWorkReport,
    };
  },
  components: {
    WidgetCard,
    VsButton,
    InvestmentsReportModal,
    VsLoader,
    AuthorizationGuard,
  },
};
</script>

<style lang="sass" scoped>
.vs-loader
  top: 0

.processing-buttons

  button
    margin: 5px 5px

.report-date
  position: relative
  top: -12px

.processing-list
  display: grid
  grid-template-columns: 1fr 1fr
  height: calc(100% - 140px)
  margin: 5px 0
  position: relative

  @media screen and (max-width: 1400px)
    grid-template-columns: 1fr 1fr

  @media screen and (max-width: 800px)
    grid-template-columns: 1fr

  &-item
    display: flex
    align-items: center
    padding: 15px 10px

    &-icon
      --icon-color: #089cff
      margin-right: 25px
      width: 60px
      aspect-ratio: 1/1
      display: flex
      align-items: center
      justify-content: center
      border-radius: 50%
      color: var(--icon-color)
      border: 2px solid var(--icon-color)
      opacity: 0.7

    &:nth-child(1)
      .processing-list-item-icon
        --icon-color: #ff4a08
    &:nth-child(2)
      .processing-list-item-icon
        --icon-color: #ff8c08
    &:nth-child(3)
      .processing-list-item-icon
        --icon-color: #da73ff

    &-text
      .value
        font-weight: 500
        font-size: 14pt
</style>
