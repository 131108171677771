var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-details"
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "text": "Trwa pobieranie danych inwestycji..."
    }
  }) : _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('investments-details-card', {
    attrs: {
      "investment": _vm.investment
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('investments-processing-card', {
    attrs: {
      "investment": _vm.investment
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('investments-fueling-card', {
    attrs: {
      "investment": _vm.investment
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('investments-progress-card', {
    attrs: {
      "investment": _vm.investment
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('investments-ordered-object-card', {
    attrs: {
      "investment": _vm.investment
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('investments-request-card', {
    attrs: {
      "investment": _vm.investment
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('investments-resources-card', {
    attrs: {
      "investment": _vm.investment
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('investments-attachments-card', {
    attrs: {
      "investmentId": _vm.investment.id,
      "files": _vm.investment.attachments
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('investments-notes-card', {
    attrs: {
      "investmentId": _vm.investment.id
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }