<template>
  <widget-card class="ordered-object-card" title="Przedmiot zamówienia">

    <table>
      <tbody>
        <tr>
          <td>grupa:</td>
          <td class="list">
            <span
              v-for="group in investment.work_groups"
              :key="group.id"
            >
              {{ group.name }}
            </span>
          </td>
        </tr>
        <tr>
          <td>typ robót:</td>
          <td class="list">
            <span
              v-for="type in investment.work_types"
              :key="type.id"
            >
              {{ type.name }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <authorization-guard
      permission="investment.subject.index"
    >
      <investments-ordered-objects-card-table
        class="object-table"
        :subjects="subjects"
      />
    </authorization-guard>
  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import InvestmentsOrderedObjectsCardTable from '@/components/views/investments/ui/InvestmentsOrderedObjectsCardTable.vue';
import { computed } from 'vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';

export default {
  name: 'InvestmentsOrderedObjectCard',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const progress = computed(() => props.investment?.completionPercentage?.subjects ?? []);
    const subjects = computed(() => [
      ...(props.investment?.subjects ?? [])
        .map((s) => ({ ...s, ...progress.value.find((p) => p.id === s.id) })),
    ]);

    return { subjects };
  },
  components: {
    InvestmentsOrderedObjectsCardTable,
    WidgetCard,
    AuthorizationGuard,
  },
};
</script>

<style lang="sass" scoped>
.list
  span
    &:not(:last-child):after
      margin-left: -3px
      content: ', '
.object-table
  margin-top: 25px

table
  margin-top: 20px
  line-height: 1.7

  td:nth-child(1)
    font-weight: 300

  td:nth-child(2)
    padding-left: 20px
</style>
