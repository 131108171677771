<template>
  <div class="investment-order-objects-table">
    <vs-table
      :columns="extendedColumns"
      :rows="subjects"
      :showPagination="false"
      :showSearch="false"
    >
      <template v-slot:row="{ column, row }" >
        <span v-if="column.field === 'technology' && row.technology">
          {{ row.technology.name }}
        </span>

        <span v-if="column.field === 'diameter'">
          {{ Number(row.diameter).toLocaleString() }} mm
        </span>

        <span v-if="column.field === 'quantity'">
          {{ Number(row.quantity).toLocaleString() }}
        </span>

        <span v-if="column.field === 'length'">
          {{ Number(row.length).toLocaleString() }} m
        </span>

        <span v-if="column.field === 'mb'">
          {{ (row.length * row.quantity).toLocaleString() }} mb
        </span>

        <span class="subject-progress" v-if="column.field === 'progress'">
          <div class="progress-percentage">
            {{ row.percentage || 0 }}%
          </div>
          <div class="progress-wrapper">
            <b-progress
              :value="row.percentage || 0"
              max="100"
            />
          </div>
        </span>

        <span v-else-if="column.field === 'reinforcement' && row.reinforcement">
          {{ row.reinforcement.name }}
        </span>
      </template>
    </vs-table>
  </div>
</template>

<script>
import VsTable from '@/components/ui/vs-table/Table.vue';
import investmentsObjectsTable from '@/hooks/tables/investments/investmentsObjects';
import router from '@/router';
import i18n from '@/libs/i18n';
import { computed } from 'vue';
import { BProgress } from 'bootstrap-vue';

export default {
  name: 'InvestmentsOrderedObjectsCardTable',
  props: {
    subjects: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const {
      table, updateSearchTerm, searchTerm, columns,
      onPageChange,
    } = investmentsObjectsTable.value;

    const tableI18n = 'investments.form.investment.tab.subject.table';
    const extendedColumns = computed(() => [
      ...columns,
      {
        label: i18n.t(`${tableI18n}.progress`),
        field: 'progress',
      },
    ]);

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Pen',
        callback: (obj) => {
          router.push({ name: 'crm', params: { id: obj.id } });
        },
      },
    ];

    return {
      table,
      updateSearchTerm,
      searchTerm,
      extendedColumns,
      onPageChange,
      actionItems,
    };
  },
  components: {
    VsTable,
    BProgress,
  },
};
</script>

<style lang="sass" scoped>
table
  margin-top: 20px
  line-height: 1.7

  .subject-progress
    font-weight: 600
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 7px

    .progress-wrapper
      width: 100%

    .progress-percentage
      white-space: nowrap
      width: 120px

  td:nth-child(1)
    font-weight: 300

  td:nth-child(2)
    padding-left: 20px
</style>
