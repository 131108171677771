<template>
  <investments-cadre-details-list
    :editable="false"
    :brigades="populatedBrigades"
  />
</template>

<script>
import { computed } from 'vue';
import InvestmentsCadreDetailsList from './InvestmentsCadreDetailsList.vue';

export default {
  name: 'InvestmentsResourcesCardBrigadeTable',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const brigades = computed(() => props.investment?.brigades ?? []);

    const populatedBrigades = computed(() => brigades.value.map((brigade) => {
      const vehicleId = brigade.resource.base_resource_id;
      const vehicle = props.investment.base_resource
        .find((v) => v.base_resource_id === vehicleId);

      return {
        ...brigade,
        linkedVehicle: {
          id: vehicle?.base_resource_id,
          name: vehicle?.resource?.name ?? 'Maszyna',
        },
      };
    }));

    return {
      populatedBrigades,
    };
  },
  components: {
    InvestmentsCadreDetailsList,
  },
};
</script>

<style scoped>

</style>
