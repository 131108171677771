<template>
  <div class="progress-card">

    <template v-if="investment.contract_scheduler">
      <h5>Terminy - z umowy</h5>

      <table v-if="investment.contract_scheduler">
        <tbody>
        <tr>
          <td>rozpoczęcie prac:</td>
          <td>
            {{ formatDate.training(investment.contract_scheduler.start_date) || '-' }}
          </td>
        </tr>
        <tr>
          <td>zakończenie prac:</td>
          <td>
            {{ formatDate.training(investment.contract_scheduler.end_date) || '-' }}
          </td>
        </tr>
        <tr>
          <td>przekazanie dokumentacji:</td>
          <td>
            {{ formatDate.training(investment.contract_scheduler.documentation_date) || '-' }}
          </td>
        </tr>
        </tbody>
      </table>
    </template>

    <template v-if="investment.estimated_scheduler">
      <h5>Terminy - szacowane</h5>

      <table>
        <tbody>
        <tr>
          <td>rozpoczęcie prac:</td>
          <td>
            {{ formatDate.training(investment.estimated_scheduler.start_date) || '-' }}
          </td>
        </tr>
        <tr>
          <td>zakończenie prac:</td>
          <td>
            {{ formatDate.training(investment.estimated_scheduler.end_date) || '-' }}
          </td>
        </tr>
        <tr>
          <td>przekazanie dokumentacji:</td>
          <td>
            {{ formatDate.training(investment.estimated_scheduler.documentation_date) || '-' }}
          </td>
        </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import formatDate from '@/libs/date-formatter';

export default {
  name: 'InvestmentsProgressCardTable',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return { formatDate };
  },
};
</script>

<style lang="sass" scoped>
h5
  margin-top: 25px

table
  margin-top: 20px
  line-height: 1.7
  width: 100%

  td, th
    min-width: 130px
    &:not(:first-child)
      text-align: right
</style>
