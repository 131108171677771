var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', [_c('attachments', {
    attrs: {
      "useProps": "",
      "useTabs": "",
      "items": _vm.parsedAttachments,
      "uploading": _vm.uploading
    },
    on: {
      "add": _vm.uploadAttachment,
      "remove": _vm.removeAttachment
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }