<template>
  <widget-card
    title="Stan zbiornika paliwa"
    >

    <vs-loader full
      v-if="isLoading"
    />

    <authorization-guard
      permission="investment.fuel.index"
    >
      <h1>{{ summary.balance }} l</h1>

      <div class="table-wrapper">
        <table>
          <tr v-for="(entry, idx) in summary.list" :key="`entry-${idx}`">
            <td class="date">{{ formatDate.investmentFuel(entry.date)}}</td>
            <td class="type">{{ entry.type === 'fuelOrder' ? 'Dostawa' : 'Tankowanie' }}</td>
            <td :class="`value ${entry.type === 'fuelOrder' ? 'add' : 'subtract'}`">
              {{ entry.type === 'fuelOrder' ? '+' : '-' }}
              {{ entry.amount }} l
            </td>
          </tr>
        </table>
      </div>
    </authorization-guard>

    <template #footer>
      <div class="processing-buttons">
        <authorization-guard
          permission="investment.fuel.store"
          silent
        >
          <vs-button
            vs-variant="primary"
            @click="openReportModal"
            >
            + Dostawa
          </vs-button>
        </authorization-guard>

        <authorization-guard
          permission="investment.fuel.index"
          silent
        >
          <vs-button
            vs-variant="light"
            @click="openFuelingHistory"
            >
            Historia
          </vs-button>
        </authorization-guard>

      </div>
    </template>

    <investments-create-fueling-modal
      @success="updateSummary"
    />
  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { ref } from 'vue';
import showToast from '@/libs/toasts';
import { FetchWorkSummaryError } from '@/libs/toasts/feedback/module/investments';
import formatDate from '@/libs/date-formatter';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import useInvestmentsModals from '@/hooks/investments/useInvestmentsModals';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import InvestmentsCreateFuelingModal from '../modals/fueling/InvestmentsCreateFuelingModal.vue';
import router from '../../../../router';

export default {
  name: 'InvestmentsFuelingCard',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const now = Date.now();
    const reportDate = formatDate.standard(now);
    const isLoading = ref(true);

    const { modalCreateFueling } = useInvestmentsModals();
    const summary = ref({});

    const updateSummary = () => {
      if (!props.investment.id) return;

      isLoading.value = true;
      useInvestmentsApi()
        .fetchFuelingReport(props.investment.id, { date_from: reportDate, date_to: reportDate })
        .then(({ data }) => {
          summary.value = data;
          isLoading.value = false;
        })
        .catch(() => {
          showToast(FetchWorkSummaryError);
          isLoading.value = false;
        });
    };

    updateSummary();

    const openReportModal = () => {
      modalCreateFueling.value.open({ investment_id: props.investment?.id ?? null });
    };

    const openFuelingHistory = () => {
      router.push({
        name: 'investments.fueling',
        params: {
          investmentId: props.investment?.id ?? null,
        },
      });
    };

    return {
      summary,
      reportDate,
      openReportModal,
      openFuelingHistory,
      updateSummary,
      formatDate,
      isLoading,
    };
  },
  components: {
    WidgetCard,
    VsButton,
    AuthorizationGuard,
    InvestmentsCreateFuelingModal,
    VsLoader,
  },
};
</script>

<style lang="sass" scoped>
.table-wrapper
  height: 230px
  overflow-y: auto
  width: 100%
  padding-right: 10px
  margin-top: 30px

  table
    width: 100%

    td
      padding: 5px 0

    .date
      max-width: 70px
      opacity: 0.6

    .type
      font-weight: 500

    .value
      font-weight: 600
      text-align: right

      &.add
        color: #69cc37

      &.subtract
        color: #f23d3d

.vs-loader
  top: 0

.processing-buttons

  button
    margin: 5px 5px

.report-date
  position: relative
  top: -12px

.processing-list
  display: grid
  grid-template-columns: 1fr 1fr
  height: calc(100% - 140px)
  margin: 5px 0
  position: relative

  @media screen and (max-width: 1400px)
    grid-template-columns: 1fr 1fr

  @media screen and (max-width: 800px)
    grid-template-columns: 1fr

  &-item
    display: flex
    align-items: center
    padding: 15px 10px

    &-icon
      --icon-color: #089cff
      margin-right: 25px
      width: 60px
      aspect-ratio: 1/1
      display: flex
      align-items: center
      justify-content: center
      border-radius: 50%
      color: var(--icon-color)
      border: 2px solid var(--icon-color)
      opacity: 0.7

    &:nth-child(1)
      .processing-list-item-icon
        --icon-color: #ff4a08
    &:nth-child(2)
      .processing-list-item-icon
        --icon-color: #ff8c08
    &:nth-child(3)
      .processing-list-item-icon
        --icon-color: #da73ff

    &-text
      .value
        font-weight: 500
        font-size: 14pt
</style>
