var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', [_vm.notes ? _c('notes', {
    attrs: {
      "items": _vm.notes,
      "create-url": "api/investment/notes",
      "edit-url": "api/investment/notes/:id",
      "target-key": "investment_id"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }