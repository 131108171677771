var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "progress-card"
  }, [_vm.investment.contract_scheduler ? [_c('h5', [_vm._v("Terminy - z umowy")]), _vm.investment.contract_scheduler ? _c('table', [_c('tbody', [_c('tr', [_c('td', [_vm._v("rozpoczęcie prac:")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatDate.training(_vm.investment.contract_scheduler.start_date) || '-') + " ")])]), _c('tr', [_c('td', [_vm._v("zakończenie prac:")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatDate.training(_vm.investment.contract_scheduler.end_date) || '-') + " ")])]), _c('tr', [_c('td', [_vm._v("przekazanie dokumentacji:")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatDate.training(_vm.investment.contract_scheduler.documentation_date) || '-') + " ")])])])]) : _vm._e()] : _vm._e(), _vm.investment.estimated_scheduler ? [_c('h5', [_vm._v("Terminy - szacowane")]), _c('table', [_c('tbody', [_c('tr', [_c('td', [_vm._v("rozpoczęcie prac:")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatDate.training(_vm.investment.estimated_scheduler.start_date) || '-') + " ")])]), _c('tr', [_c('td', [_vm._v("zakończenie prac:")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatDate.training(_vm.investment.estimated_scheduler.end_date) || '-') + " ")])]), _c('tr', [_c('td', [_vm._v("przekazanie dokumentacji:")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatDate.training(_vm.investment.estimated_scheduler.documentation_date) || '-') + " ")])])])])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }