<template>
  <div class="investments-details">

    <vs-loader
      text="Trwa pobieranie danych inwestycji..."
      v-if="isLoading"
    />

    <b-container fluid v-else>
      <b-row>

        <b-col cols="4">
          <investments-details-card
            :investment="investment"
          />
        </b-col>

        <b-col cols="4">
          <investments-processing-card
            :investment="investment"
          />
        </b-col>

        <b-col cols="4">
          <investments-fueling-card
            :investment="investment"
          />
        </b-col>

        <b-col cols="4">
          <investments-progress-card
            :investment="investment"
          />
        </b-col>

        <b-col cols="8">
          <investments-ordered-object-card
            :investment="investment"
          />
        </b-col>

        <b-col cols="12">
          <investments-request-card
            :investment="investment"
          />
        </b-col>

        <b-col cols="12">
          <investments-resources-card
            :investment="investment"
          />
        </b-col>

        <b-col cols="6">
          <investments-attachments-card
            :investmentId="investment.id"
            :files="investment.attachments"
          />
        </b-col>

        <b-col cols="6">
          <investments-notes-card
            :investmentId="investment.id"
          />
        </b-col>

      </b-row>
    </b-container>

  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
} from 'bootstrap-vue';
import InvestmentsDetailsCard from '@/components/views/investments/widgets/InvestmentsDetailsCard.vue';
import InvestmentsProgressCard from '@/components/views/investments/widgets/InvestmentsProgressCard.vue';
import InvestmentsOrderedObjectCard from '@/components/views/investments/widgets/InvestmentsOrderedObjectCard.vue';
import InvestmentsProcessingCard from '@/components/views/investments/widgets/InvestmentsProcessingCard.vue';
import InvestmentsRequestCard from '@/components/views/investments/widgets/InvestmentsRequestCard.vue';
import InvestmentsResourcesCard from '@/components/views/investments/widgets/InvestmentsResourcesCard.vue';
import { ref } from 'vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { useRouter } from '@/@core/utils/utils';
import showToast from '@/libs/toasts';
import { FetchInvestmentError } from '@/libs/toasts/feedback/module/investments';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import InvestmentsAttachmentsCard from '@/components/views/investments/widgets/InvestmentsAttachmentsCard.vue';
import store from '@/store';
import InvestmentsFuelingCard from '@/components/views/investments/widgets/InvestmentsFuelingCard.vue';
import InvestmentsNotesCard from '@/components/views/investments/widgets/InvestmentsNotesCard.vue';

export default {
  name: 'InvestmentsDetails',
  setup() {
    const investment = ref({});
    const isLoading = ref(true);

    const { route, router } = useRouter();

    store.dispatch('units/fetch');

    const fetchInvestment = (id) => {
      if (!id) return;
      isLoading.value = true;

      useInvestmentsApi()
        .fetchInvestment(id)
        .then(({ data }) => {
          investment.value = { ...data.data };
          isLoading.value = false;
        })
        .catch(() => {
          showToast(FetchInvestmentError);

          router.push({ name: 'investments' });
          isLoading.value = false;
        });
    };

    fetchInvestment(route.value.params?.id);

    return { isLoading, investment };
  },
  components: {
    InvestmentsResourcesCard,
    InvestmentsRequestCard,
    InvestmentsProcessingCard,
    InvestmentsOrderedObjectCard,
    InvestmentsProgressCard,
    InvestmentsDetailsCard,
    BContainer,
    BRow,
    BCol,
    VsLoader,
    InvestmentsAttachmentsCard,
    InvestmentsFuelingCard,
    InvestmentsNotesCard,
  },
};
</script>
