<template>
  <widget-card class="investment-resources-card">

    <authorization-guard
      silent
      :permissions="['investment.base_resource.index', 'investment.brigade.index']"
      mode="AND"
    >
      <div
        class="demand-summary"
      >
        Łączna wartość zasobów inwestycji:

        <div class="demand-summary-value">
          {{ resourceTotalValue.toLocaleString() }} PLN
        </div>
      </div>
    </authorization-guard>

    <b-tabs
      class="tabs"
      vertical
      pills
    >

      <b-tab active title="Maszyny i sprzęt">

        <authorization-guard
          permission="investment.base_resource.index"
        >
          <investment-resources-card-vehicles-table
            :investment="investment"
            v-if="investment.base_resource && investment.base_resource.length"
          />

          <div class="no-address-info" v-else>
            <div class="wrapper">
              <feather-icon class="icon" icon="SettingsIcon" size="36" />
              <div class="text">
                Inwestycja nie ma jeszcze przypisanych maszyn i sprzętów.
              </div>
              <router-link :to="{ name: 'investments.details.settings' }">
                Przejdź do ustawień, aby je dodać
              </router-link>
            </div>
          </div>
        </authorization-guard>

      </b-tab>

      <b-tab title="Zespół">

        <authorization-guard
          permission="investment.brigade.index"
        >
          <investment-resources-card-brigade-table
            :investment="investment"
            v-if="investment.brigades && investment.brigades.length"
          />

          <div class="no-address-info" v-else>
            <div class="wrapper">
              <feather-icon class="icon" icon="SettingsIcon" size="36" />
              <div class="text">
                Inwestycja nie ma jeszcze przypisanych brygad.
              </div>
              <router-link :to="{ name: 'investments.details.settings' }">
                Przejdź do ustawień, aby je dodać
              </router-link>
            </div>
          </div>
        </authorization-guard>

      </b-tab>

    </b-tabs>
  </widget-card>
</template>

<script>
import {
  BTabs,
  BTab,
} from 'bootstrap-vue';
import InvestmentResourcesCardVehiclesTable
  from '@/components/views/investments/ui/InvestmentsResourcesCardVehiclesTable.vue';
import InvestmentResourcesCardBrigadeTable
  from '@/components/views/investments/ui/InvestmentsResourcesCardBrigadeTable.vue';
import WidgetCard from '@/components/ui/WidgetCard.vue';
import { computed } from 'vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';

export default {
  name: 'InvestmentsResourcesCard',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const vehicleTotalValue = computed(() => props.investment.base_resource
      .reduce((prev, current) => {
        const resourceValue = current.resource?.actual_daily_cost ?? 0;
        const resourceDays = current.resource_data?.days_number ?? 0;

        return prev + (resourceValue * resourceDays);
      }, 0) ?? 0);

    const cadreTotalValue = computed(() => {
      const employees = props.investment.brigades.reduce((acc, brigade) => {
        if (!brigade.employees) return acc;
        return [...acc, ...brigade.employees];
      }, []);

      const totalValue = employees.reduce((prev, curr) => {
        const price = curr.cadre_position?.actual_daily_cost ?? 0;
        const days = curr.resource_data?.days_number ?? 0;

        return prev + (price * days);
      }, 0);

      return totalValue;
    });

    const resourceTotalValue = computed(() => vehicleTotalValue.value + cadreTotalValue.value);

    return { resourceTotalValue };
  },
  components: {
    BTabs,
    BTab,

    InvestmentResourcesCardBrigadeTable,
    InvestmentResourcesCardVehiclesTable,
    WidgetCard,
    AuthorizationGuard,
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/scss/variables/_variables.scss'

.demand-summary
  padding: 5px 10px 20px 10px

  &-value
    display: inline
    font-weight: 700

.no-address-info
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  height: 100%

  .wrapper
    .icon
      opacity: 0.3
    .text
      font-size: 12pt
      font-weight: 500
      opacity: 0.8
      margin: 20px 0

.tabs::v-deep
  display: flex !important
  align-items: flex-start !important
  text-align: left

  .nav
    width: 170px

    .nav-item
      display: flex
      align-items: center
      padding-left: 0px

      a.nav-link
        padding: 15px 10px
        font-weight: 500
        opacity: 0.7

        &.active
          background: none
          color: $primary
          border: none
          box-shadow: none
          font-weight: 600
          opacity: 1
</style>
