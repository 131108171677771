var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    attrs: {
      "title": "Stan zbiornika paliwa"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "processing-buttons"
        }, [_c('authorization-guard', {
          attrs: {
            "permission": "investment.fuel.store",
            "silent": ""
          }
        }, [_c('vs-button', {
          attrs: {
            "vs-variant": "primary"
          },
          on: {
            "click": _vm.openReportModal
          }
        }, [_vm._v(" + Dostawa ")])], 1), _c('authorization-guard', {
          attrs: {
            "permission": "investment.fuel.index",
            "silent": ""
          }
        }, [_c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": _vm.openFuelingHistory
          }
        }, [_vm._v(" Historia ")])], 1)], 1)];
      },
      proxy: true
    }])
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": ""
    }
  }) : _vm._e(), _c('authorization-guard', {
    attrs: {
      "permission": "investment.fuel.index"
    }
  }, [_c('h1', [_vm._v(_vm._s(_vm.summary.balance) + " l")]), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', _vm._l(_vm.summary.list, function (entry, idx) {
    return _c('tr', {
      key: `entry-${idx}`
    }, [_c('td', {
      staticClass: "date"
    }, [_vm._v(_vm._s(_vm.formatDate.investmentFuel(entry.date)))]), _c('td', {
      staticClass: "type"
    }, [_vm._v(_vm._s(entry.type === 'fuelOrder' ? 'Dostawa' : 'Tankowanie'))]), _c('td', {
      class: `value ${entry.type === 'fuelOrder' ? 'add' : 'subtract'}`
    }, [_vm._v(" " + _vm._s(entry.type === 'fuelOrder' ? '+' : '-') + " " + _vm._s(entry.amount) + " l ")])]);
  }), 0)])]), _c('investments-create-fueling-modal', {
    on: {
      "success": _vm.updateSummary
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }