var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', [_c('tbody', [_c('tr', [_c('td', [_vm._v("status")]), _c('td', [_c('vs-status-badge-investment', {
    attrs: {
      "id": _vm.investment.investment_status_id
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("region")]), _c('td', [_c('branch-label', {
    attrs: {
      "id": _vm.investment.core_branch_id
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("nr. umowy zewnętrznej")]), _c('td', [_vm._v(" " + _vm._s(_vm.investment.external_contract) + " ")])]), _c('tr', [_c('td', [_vm._v("inwestor")]), _c('td', [_vm.investment.investor ? _c('span', [_c('crm-company-label', {
    attrs: {
      "integrated": _vm.investment.investor
    }
  })], 1) : _vm._e()])]), _c('tr', [_c('td', [_vm._v("zleceniodawca")]), _c('td', [_vm.investment.principal ? _c('span', [_c('crm-company-label', {
    attrs: {
      "integrated": _vm.investment.principal
    }
  })], 1) : _vm._e()])]), _c('tr', [_c('td', [_vm._v("adres")]), _c('td', [_vm._v(" " + _vm._s(_vm.investment.street) + " ")])]), _c('tr', [_c('td'), _c('td', [_c('span', {
    staticClass: "separate-element"
  }, [_vm._v(" " + _vm._s(_vm.investment.post_code) + " " + _vm._s(_vm.investment.city) + " ")]), _vm.investment.plot_number ? _c('span', {
    staticClass: "separate-element"
  }, [_vm._v(" dz. nr " + _vm._s(_vm.investment.plot_number) + " ")]) : _vm._e()])]), _c('tr', [_c('td', [_vm._v("współrzędne")]), _c('td', [_vm._v(" " + _vm._s(_vm.investment.latitude || '-') + ", " + _vm._s(_vm.investment.longitude || '-') + " ")])]), _c('tr', [_c('td', [_vm._v("opis")]), _c('td', [_vm._v(" " + _vm._s(_vm.investment.address_description) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }