var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    staticClass: "investment-resources-card"
  }, [_c('authorization-guard', {
    attrs: {
      "silent": "",
      "permissions": ['investment.base_resource.index', 'investment.brigade.index'],
      "mode": "AND"
    }
  }, [_c('div', {
    staticClass: "demand-summary"
  }, [_vm._v(" Łączna wartość zasobów inwestycji: "), _c('div', {
    staticClass: "demand-summary-value"
  }, [_vm._v(" " + _vm._s(_vm.resourceTotalValue.toLocaleString()) + " PLN ")])])]), _c('b-tabs', {
    staticClass: "tabs",
    attrs: {
      "vertical": "",
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": "",
      "title": "Maszyny i sprzęt"
    }
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "investment.base_resource.index"
    }
  }, [_vm.investment.base_resource && _vm.investment.base_resource.length ? _c('investment-resources-card-vehicles-table', {
    attrs: {
      "investment": _vm.investment
    }
  }) : _c('div', {
    staticClass: "no-address-info"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "SettingsIcon",
      "size": "36"
    }
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Inwestycja nie ma jeszcze przypisanych maszyn i sprzętów. ")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'investments.details.settings'
      }
    }
  }, [_vm._v(" Przejdź do ustawień, aby je dodać ")])], 1)])], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Zespół"
    }
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "investment.brigade.index"
    }
  }, [_vm.investment.brigades && _vm.investment.brigades.length ? _c('investment-resources-card-brigade-table', {
    attrs: {
      "investment": _vm.investment
    }
  }) : _c('div', {
    staticClass: "no-address-info"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "SettingsIcon",
      "size": "36"
    }
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Inwestycja nie ma jeszcze przypisanych brygad. ")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'investments.details.settings'
      }
    }
  }, [_vm._v(" Przejdź do ustawień, aby je dodać ")])], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }