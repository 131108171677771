var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', [_c('authorization-guard', {
    attrs: {
      "permission": "investment.requirement.index"
    }
  }, [_vm.materials.length > 0 ? _c('div', {
    staticClass: "demands"
  }, [_c('div', {
    staticClass: "demand-summary"
  }, [_vm._v(" Łączna wartość zapotrzebowania inwestycji: "), _c('div', {
    staticClass: "demand-summary-value"
  }, [_vm._v(" " + _vm._s(_vm.investmentTotalValue) + " PLN ")])]), _c('b-tabs', {
    staticClass: "tabs",
    attrs: {
      "vertical": "",
      "pills": ""
    }
  }, _vm._l(_vm.materials, function (material) {
    return _c('b-tab', {
      key: material.id,
      attrs: {
        "title": material.name
      }
    }, [_c('investments-demand-details', {
      key: material.id,
      attrs: {
        "editable": false,
        "materialId": material.id,
        "unitId": material.core_unit_id,
        "demands": material.demands,
        "offers": material.offers
      }
    })], 1);
  }), 1)], 1) : _c('div', {
    staticClass: "no-address-info"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "SettingsIcon",
      "size": "36"
    }
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Inwestycja nie ma jeszcze zgłoszonego zapotrzebowania. ")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'investments.details.settings'
      }
    }
  }, [_vm._v(" Przejdź do ustawień, aby je dodać ")])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }